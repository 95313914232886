import React from 'react';

export default (props) => {

  const { numberOfStars } = props;
  const numberOfEmptyStars = 5 - numberOfStars;

  const starElements = [];
  const emptyStarElements = [];

  for (let i = 0; i < numberOfStars; i++) {
    starElements.push(<i key={i} className='fa fa-star' />);
  }

  for (let i = 0; i < numberOfEmptyStars; i++) {
    emptyStarElements.push(<i key={i} className='fa fa-star-o' />);
  }

  return (
    <div>
      {starElements}
      {emptyStarElements}
    </div>
  );

};

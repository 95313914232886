import React from 'react';
import StarRating from './star_rating';

export default (props) => {
  const { review } = props;

  return (
    <div className='component-review'>
      <div className='star-rating'>
        <StarRating numberOfStars={review.number_of_stars} />
      </div>
      <div className='body'>{review.body}</div>
      <div className='name'>{review.reviewer_name}</div>
      {
        review.user.google_my_business_url && (review.user.google_my_business_url.length > 0) &&
        <a href={review.user.google_my_business_url} target='_blank'>Read More</a>
      }
    </div>
  )
}
import React from 'react';
import ReactDOM from 'react-dom';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import Review from '../src/components/review';

class ReviewsCarousel extends React.Component {  
  state = {
    reviews: []
  }

  componentDidMount() {
    this.loadReviews();
  }

  loadReviews = () => {
    $.ajax({
      url: '/reviews',
      dataType: 'JSON',
      success: (reviews) => {
        this.setState({ reviews });
      }
    });
  }

  reviewGroups = () => {
    const { reviews } = this.state;
    const reviewGroups = [];
    const groupSize = 3;
    let reviewGroup = [];

    for(let i = 0; i < reviews.length; i++) {
      const review = reviews[i];

      reviewGroup.push(review);

      if((reviewGroup.length == groupSize) || (i == (reviews.length - 1))) {
        reviewGroups.push(reviewGroup);
        reviewGroup = [];
      }
    }

    return reviewGroups;
  }

  render() {
    const { interval } = this.props;
    const { reviews } = this.state;

    return(
      <div className='component-reviews-carousel'>
        {
          (reviews.length > 0) &&
          <React.Fragment>
            {/*'Desktop Carousel'*/}
            <Carousel 
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={interval}
              className='desktop-reviews-carousel'
              >
                {
                  this.reviewGroups().map((reviewGroup, index) => {
                    return (
                      <div key={index} className='review-group'>
                        {
                          reviewGroup.map((review) => <Review key={review.id} review={review} />)
                        }
                      </div>
                    );
                  })
                }
            </Carousel>

            {/*'Mobile Carousel'*/}
            <Carousel 
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={interval}
            className='mobile-reviews-carousel'
            >
              {
                reviews.map((review) => <Review key={review.id} review={review} />)
              }
            </Carousel>
          </React.Fragment>
        }
      </div>
    )
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const mountPoint = document.getElementById('reviews-carousel-mount-point');
  if(mountPoint) {
    const interval = parseInt(mountPoint.dataset.interval);
    ReactDOM.render(<ReviewsCarousel interval={interval} />, mountPoint);
  }
})
